import React, {useLayoutEffect} from "react";
import Mybackground from "../Images/pics/page_banner.JPG";
import "../Css/Contact.css";
import map from "../Images/icons/placeholder.png";
import phone from "../Images/icons/touch.png";
import email from "../Images/icons/email.png";
import Footer from "../Components/Footer";
import { Formik, Form, Field, ErrorMessage } from "formik";
import $ from 'jquery';

const Contact = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
  <section className="page-banner">
        <div
          className="image-layer"
          style={{ backgroundImage: `url(${Mybackground})` }}
        />
        <div className="banner-bottom-pattern" />
        <div className="banner-inner">
          <div className="auto-container">
            <div className="inner-container clearfix">
              <h1>Contact</h1>
              <div className="page-nav">
                <ul className="bread-crumb clearfix">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li className="active">Contact</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
<section className="contact-section-two">
  <div className="auto-container clearfix">
    <div className="row clearfix">
      <div className="info-col col-lg-6 col-md-12 col-sm-12">
        <div className="inner">
          <div className="u-text">
         
Feel free to connect with the top resort in neral at any time. Our resort's Contact Us is more than just a point of communication; it's a gateway to personalized service. Whether you have inquiries, special requests, or simply want to share your experience, our dedicated team is here to assist.

          </div>
          <div className="info">
            <div className="info-block">
              <div className="block-inner">
                <div
                  className="icon-box wow zoomInStable"
                  data-wow-delay="0ms"
                  data-wow-duration="2000ms"
                >
                    <span>  <img src={map} alt=""/></span>
                </div>
                <h4>Address</h4>
                <div className="text">
                Neral, Vakas, Maharashtra 410101
                </div>
              </div>
            </div>
            <div className="info-block">
              <div className="block-inner">
                <div
                  className="icon-box wow zoomInStable"
                  data-wow-delay="300ms"
                  data-wow-duration="2000ms"
                >
                  <span>  <img src={phone} alt=""/></span>
                </div>
                <h4>Phone Number</h4>
                <div className="text">
                  <a href="tel:+9193269 07820">93269 07820</a>{" "}
                  <br />
                  <a href="tel:+917774080297"> 7774080297</a>
                </div>
              </div>
            </div>
            <div className="info-block">
              <div className="block-inner">
                <div
                  className="icon-box wow zoomInStable"
                  data-wow-delay="600ms"
                  data-wow-duration="2000ms"
                >
                  <span>  <img src={email} alt=""/></span>
                </div>
                <h4>Email Address</h4>
                <div className="text">
                  <a href="mailto:info@hotera.com">resortneel@gmail.com</a> <br />
                  <a href="mailto:info@example.com">manishasrathod3003@gmail.com</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-col col-lg-6 col-md-12 col-sm-12">
        <div className="inner">
          <div
            className="form-box default-form contact-form-two"
        
          >
            <h3>Have Any Question?</h3>
            <div className="text">
              Please complete the details below and then click on Submit and
              we’ll be in contact
            </div>
            <div className="form-group">
            <Formik
                      initialValues={{ email: "", Name: "", Cnumber: "",  message: "" }}
                      validate={(values) => {
                        const errors = {};
                        if (!values.Name) {
                          errors.Name = "Required";
                        } else if (values.Name.length >= 10) {
                          errors.Name = "Enter A Name";
                        }

                        if (!values.Cnumber) {
                          errors.Cnumber = "Required";
                        } else if (values.Cnumber.length >= 11) {
                          errors.Name = "Invalid Contact Number";
                        }

                        if (!values.email) {
                          errors.email = "Required";
                        } else if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                            values.email
                          )
                        ) {
                          errors.email = "Invalid email address";
                        }
                        return errors;
                      }}
                      onSubmit={(values, { setSubmitting, resetForm }) => {
                        // setTimeout(() => {

                        var body =
                          '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#eb6e14">Neels Agrotourism and Resort Enquiry</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#0a500a"><strong>Name:</strong></td><td style="text-align:left">' +  values.Name + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Email Id:</strong></td><td style="text-align:left">' +  values.email + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Contact No:</strong></td><td style="text-align:left">' +  values.Cnumber + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Message:</strong></td><td style="text-align:left">' +  values.message + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Neels Agrotourism and Resort</p></div></body></html>';

                        $.post(
                          "https://skdm.in/server/v1/send_lead_mail.php",
                          {
                            toEmail: "resortneel@gmail.com",
                            fromEmail: "skdmlead@gmail.com",
                            bccMail: "skdmlead@gmail.com",
                            mailSubject: "New Lead genration",
                            mailBody: body,
                            accountName: "neelagro",
                            accountLeadSource:
                              "",
                          },

                          function (dataa, status) {
                            // console.log('data :' + dataa);
                            // console.log("name:" + custName);
                          }
                        );

                        alert(
                          "Your Form has Submitted Our team will contact with You  soon."
                        );

                        // e.preventDefault()

                        setSubmitting(false);
                          // Reset the form after submission
  resetForm();

                        // }, 10);
                      }}
                    >
                      <Form id="contact">
                    <Field
                      name="Name"
                      type="text"
                      
                      placeholder="Your name"
                    />
                     <ErrorMessage name="Name" component="div" />
                     <Field
                     name="Cnumber"
                     placeholder="Your Phone Number*"
                     type="tel"
                     tabIndex={3}
                     required=""
                    />
                      <ErrorMessage name="Cnumber" component="div" /> 
                    <Field
                    name="email"
                      type="text"
                     
                      placeholder="Email"
                    />
                     <ErrorMessage name="email" component="div" />
                    <Field  as="textarea"
                     name="message"
                     
                      placeholder="Message"
                      defaultValue={""}
                    />
                      <ErrorMessage name="message" component="div" />
                      <div className='btn-style-one'>
                    <button className="contact_form_submit btn-title" type="submit" >Send</button>
                    </div>
                    </Form>
              </Formik>
              </div>
            {/* <form
              method="post"
              action="sendemail.php"
              id="contact-form"
              noValidate="novalidate"
            >
              <div className="form-group">
                <div className="field-inner">
                  <input
                    type="text"
                    name="username"
                    defaultValue=""
                    placeholder="First name"
                    required=""
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="field-inner">
                  <input
                    type="text"
                    name="lastname"
                    defaultValue=""
                    placeholder="Last name"
                    required=""
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="field-inner">
                  <input
                    type="email"
                    name="email"
                    defaultValue=""
                    placeholder="Email"
                    required=""
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="field-inner">
                  <input
                    type="text"
                    name="phone"
                    defaultValue=""
                    placeholder="Phone"
                    required=""
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="field-inner">
                  <textarea
                    name="message"
                    placeholder="Message"
                    required=""
                    defaultValue={""}
                  />
                </div>
              </div>
              <div className="form-group">
                <button className="btn-style-one">
                  <span className="btn-title">Send Message</span>
                </button>
              </div>
            </form> */}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<Footer/>
    </div>
  )
}

export default Contact