import React, { useState,useLayoutEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { TiEyeOutline } from "react-icons/ti";
import Footer from "../Components/Footer";
import "../Css/Gallery.css";
//============== Smile Img ========
import food from "../Images/foodblackplate2.jpg";
import sprouts from "../Images/sprouts.jpg";
import fimage4 from "../Images/gallery/cottage.JPG";
import fimage5 from "../Images/gallery/cottage_fullview.JPG";
import smile1 from "../Images/carrom.jpg";
import swim from "../Images/pool2.jpg";
import rain from "../Images/rain dance.jpg";
import bonfire from "../Images/bonfire.jpg";
import garden from "../Images/garden.jpg";
import restaurant from "../Images/restaurant.jpg";
import pets from "../Images/gallery/pets.JPG";
import play from "../Images/gallery/garden.JPG";
// import cottage from "../Images/cottage2.jpg";
import bday from "../Images/event decoration.jpg"
import Mybackground from "../Images/pics/page_banner.JPG";
// -------------couple-------------------------
import couple from "../Images/gallery/double_bed_room.JPG";
import couple1 from "../Images/gallery/couple1.jpg";
import couple2 from "../Images/gallery/couple2.jpg";
import couple3 from "../Images/gallery/couplebath.jpg";
import couple4 from "../Images/gallery/couplebath3.jpg";
// -----------------dormitory--------------------
import dormitory from "../Images/pics/dormitory.JPG";
import d1 from "../Images/pics/dormitory/WhatsApp Image 2023-12-02 at 2.05.12 PM (1).jpeg";
import d2 from "../Images/pics/dormitory/WhatsApp Image 2023-12-02 at 2.05.12 PM.jpeg";
import d3 from "../Images/pics/dormitory/WhatsApp Image 2023-12-02 at 2.05.13 PM (2).jpeg";
import d4 from "../Images/pics/dormitory/WhatsApp Image 2023-12-02 at 2.05.13 PM (3).jpeg";
import family from "../Images/pics/family.JPG";
import deluxe  from "../Images/pics/deluxe.JPG";
import park from "../Images/gallery/parking.jpg";

const Gallery = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
    // ========== Smile Gallery ========
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const images = [fimage4,fimage5,food, sprouts,smile1, swim, garden, restaurant, 
    play, pets, rain, bonfire,bday,couple,couple1,couple2,couple3,couple4,
  dormitory,d1,d2,d3,d4,family,deluxe,park];

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };
  return (
    <div>
        <section className="page-banner">
        <div
          className="image-layer"
          style={{ backgroundImage: `url(${Mybackground})` }}
        />
        <div className="banner-bottom-pattern" />
        <div className="banner-inner">
          <div className="auto-container">
            <div className="inner-container clearfix">
              <h1 className="d-none">Best Agro Tourism Resort in Neral | Neel Agro Tourism & Resort </h1>
             <h2>Gallery</h2>
              <div className="page-nav">
                <ul className="bread-crumb clearfix">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li className="active">Gallery</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

        <section className="smile-section">
        <div className="container">
          {/* <div className="custom-heading">
            <h2>
              <span>Stress</span> Free Smile
            </h2>
          </div> */}
             <div className="sec-title centered">
      <h2>Photo Gallery</h2>
      <div className="lower-text">
      Welcome to our resort in neral for stay ,where luxury meets tranquility. Explore our pool, restaurant, and diverse recreational activities. At our resort in neral for stay, every detail is crafted to ensure your stay is a seamless blend of comfort and sophistication. Your escape to serenity begins here.

      </div>
    </div>
           <div className="row row-cols-md-4 row-cols-sm-2 row-cols-1">
      {images.map((image, index) => (
        <div className="col mb-md-5 mb-3" key={index}>
           {/* <Bounce> */}
          <div className="smile-img" onClick={() => openLightbox(index)}>
            <img src={image} alt="resort gallery" />
            <div className="smile-icon">
              <TiEyeOutline />
            </div>
          </div>
          {/* </Bounce> */}
        </div>
      ))}
      {lightboxOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )}
    </div>
        </div>
      </section> 
      <Footer/>
    </div>
  )
}

export default Gallery