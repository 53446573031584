import React, { useState } from "react";
import "../Css/Navbar.css";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../Images/5 (1).png";

const NavBar = () => {
  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 78) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);
  return (
    <div>
      <section className="NavSection">
        <div className="container-fluid">
          <Navbar
            collapseOnSelect
            expand="lg"
            variant="dark"
            className={color ? "NavBar NavBar-bg" : "NavBar"}
          >
            <Container>
              <Navbar.Brand href="/">
                <img src={Logo} alt="" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav" className="main-nav">
                <Nav>
                 
                <Nav.Link
                href="/"
                className="nav-link"
              >  <Link
              to="/"
              className="nav-link"
            >  Home
            </Link>
            
              </Nav.Link>
              
                
                    <Nav.Link  className="nav-link" href="/resort-near-neral"> <Link  className="nav-link" to="/resort-near-neral">About Us</Link>
                 </Nav.Link>
                   
                    <Nav.Link  className="nav-link"  href="/best-agro-tourism-resort-neral"> <Link  className="nav-link"  to="/best-agro-tourism-resort-neral">Rooms</Link></Nav.Link>
                   
                
                    <Nav.Link  className="nav-link"  href="/resort-neral-stay">                 
                    <Link  className="nav-link"  to="/resort-neral-stay">Services</Link></Nav.Link>

                   <Nav.Link
                href="/Gallery"
                className="nav-link"
              >  <Link
              to="/Gallery"
              className="nav-link"
            >  Gallery
            </Link>
            </Nav.Link>
                    <Nav.Link  className="nav-link"  href="/top-resort-neral"> <Link  className="nav-link"  to="/top-resort-neral">Contact Us</Link></Nav.Link>
                  
                </Nav>
              </Navbar.Collapse>
             
              <div className="socialLinks">
  <a href="https://neelresorts.in/Neelresort/OnlineBooking" target="_blank">
    {" "}
    <i className="fa fa-address-book" /> <span>Book Now</span>
  </a>
</div>

            </Container>
          </Navbar>

        </div>
      </section>

    </div>
  )
}


export default NavBar

 

