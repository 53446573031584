import React from "react";
import "../Css/Home.css";
import "../Css/responsive.css";
import Carousel from "react-bootstrap/Carousel";
import Zoom from "react-reveal/Zoom";
import {Link} from "react-router-dom";
// import Fade from "react-reveal/Fade";
// import banner1 from "../Images/3.jpg";
import abt from "../Images/pool1.jpg";
import couple from "../Images/gallery/double_bed_room.JPG";
import dormitory from "../Images/pics/dormitory.JPG";
import family from "../Images/pics/family.JPG";
import deluxe from "../Images/pics/deluxe.JPG";

import umbrella from "../Images/chair-umbrella.png";
import pool from "../Images/icons/swimming-pool.png";
import rain from "../Images/icons/mushroom-shower (1).png";
import dance from "../Images/icons/dance.png";
import Footer from "../Components/Footer";
import fimage1 from "../Images/gallery/restro.JPG";
// import fimage2 from "../Images/featured-image-2.jpg";
// import fimage3 from "../Images/featured-image-3.jpg";
import fimage4 from "../Images/gallery/cottage.JPG";
import fimage5 from "../Images/gallery/cottage_fullview.JPG";
import vimage from "../Images/image-1.jpg";
import food from "../Images/foodblackplate2.jpg";
import sprouts from "../Images/sprouts.jpg";
import chef from "../Images/icons/chef.png";
import delicious from "../Images/icons/hot-pot.png";
import serv from "../Images/icons/waiter.png";
import event from "../Images/event-hall.jpg";
import { MetaTags } from "react-meta-tags";
// -------------swiper------------------
// import {Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
const Home = () => {
  return (
    <div>
      {/* ========= SEO ========== */}

      <MetaTags>
        <title>Best Resort in Neral | Neel Agro Tourism & Resort</title>
        <meta
          name="title"
          title="Best Resort in Neral | Neel Agro Tourism & Resort "
        />
        <meta
          name="description"
          content="Escape to the ultimate retreat at the Best Resort in Neral. Indulge in opulent serenity surrounded by nature's beauty. Book your stay Now !!!"
        />
        <meta
          property="og:title"
          content="Best Resort in Neral | Neel Agro Tourism & Resort "
        />
        <meta
          property="og:description"
          content="Escape to the ultimate retreat at the Best Resort in Neral. Indulge in opulent serenity surrounded by nature's beauty. Book your stay Now !!!"
        />
        <meta
          name="keywords"
          content="Best Resort in Neral,
          Resort Near Neral,
          Best Agro Tourism Resort in Neral,
          Couple Friendly Resort in Neral,
          Resort in Neral with Dormitory Room,
          Best Resort in Neral For Family,
          Resort in Neral For Stay,
          Top Resort in Neral"
        />

        <meta property="article:tag" content="Best Resort in Neral" />
        <meta property="article:tag" content="Resort Near Neral" />
        <meta
          property="article:tag"
          content="Couple Friendly Resort in Neral"
        />
        <link rel="canonical" href="/best-resort-neral" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta
          property="og:title"
          content="Best Resort in Neral | Neel Agro Tourism & Resort "
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="" />
      </MetaTags>

      {/* ======== Seo end ======= */}
      {/* -----------carousel banner----------------- */}

      <Carousel>
        <Carousel.Item>
          <div id="thirdSlide" className=""></div>

          <Carousel.Caption>
            <div className="content-box">
              <div className="content">
                <div className="inner">
                  <Zoom>
                    <h1 className="d-none">
                      Best Resort in Neral | Neel Agro Tourism & Resort{" "}
                    </h1>
                    <h2>
                      <span> Nestled along the shoreline</span>
                    </h2>
                    <div className="text">
                      Welcome to our agro farm, where sun-kissed fields meet
                      unparalleled hospitality.
                    </div>
                  </Zoom>
                </div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <div id="firstSlide" className=""></div>
          <Carousel.Caption>
            <div className="content-box">
              <div className="content">
                <div className="inner">
                  <Zoom>
                    <h2>Grow Memories, Harvest Experiences</h2>
                    <div className="text">
                      Experience the epitome of coastal luxury. Your
                      unforgettable farm retreat begins here.
                    </div>
                  </Zoom>
                </div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <div id="secondSlide" className=""></div>

          <Carousel.Caption>
            <div className="content-box">
              <div className="content">
                <div className="inner">
                  <Zoom>
                    <h2>Cultivate Tranquility Amidst Agrarian Beauty</h2>
                    <div className="text">
                      Where pristine sands and azure waters create a haven of
                      tranquility.
                    </div>
                  </Zoom>
                </div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <div className="pattern-bottom" />

      {/* <!--Featured Section--> */}
      <section className="featured-section">
        <div className="pattern-top" />
        <span className="dotted-pattern dotted-pattern-3" />
        <span className="tri-pattern tri-pattern-3" />
        <div className="auto-container">
          <div className="featured-block">
            <div className="row clearfix">
              {/*Text Column*/}
              <div className="text-column col-lg-6 col-md-12 col-sm-12">
                <div
                  className="inner wow fadeInRight"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <h3>
                    Discover look of <br />
                    Neel agro tourism and resort .
                  </h3>
                  <div className="text">
                    Welcome to the best resort in neral, where nature meets
                    hospitality. Nestled in the heart of lush landscapes, we
                    invite you to experience the charm of rural living while
                    enjoying modern amenities. Our commitment to sustainable
                    farming practices ensures fresh, locally sourced ingredients
                    for a truly farm-to-table experience.
                  </div>
                  <div className="link-box">
                    <Link to="/resort-near-neral" className="theme-btn btn-style-one">
                      <span className="btn-title">Read More</span>
                    </Link>
                  </div>
                </div>
              </div>
              {/*Image Column*/}
              <div className="image-column col-lg-6 col-md-12 col-sm-12">
                <div
                  className="inner wow fadeInLeft"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <div className="image-box">
                    <figure className="image">
                   
                        <img src={fimage4} alt="resort in neral" title="" />
                    
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="featured-block alternate">
            <div className="row clearfix">
              {/*Text Column*/}
              <div className="text-column col-lg-6 col-md-12 col-sm-12">
                <div
                  className="inner wow fadeInLeft"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <h3>
                    Premier Agrotourism and Resort <br />
                    in Neral
                  </h3>
                  <div className="text">
                    Discover the pinnacle of agrotourism and resort experiences
                    at our finest destination in Neral, where luxury seamlessly
                    blends with the beauty of nature. Immerse yourself in an
                    extraordinary agrotourism setting, where lush fields and
                    vibrant landscapes create a haven of tranquility. Our resort
                    showcases unparalleled hospitality, guaranteeing that your
                    getaway is filled with relaxation and indulgence in the
                    heart of nature.
                  </div>
                  <div className="link-box">
                    <Link to="/resort-neral-stay" className="theme-btn btn-style-one">
                      <span className="btn-title">Discover Now</span>
                    </Link>
                  </div>
                </div>
              </div>
              {/*Image Column*/}
              <div className="image-column col-lg-6 col-md-12 col-sm-12">
                <div
                  className="inner wow fadeInRight"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <div className="image-box">
                    <figure className="image">
                   
                        <img
                          src={fimage5}
                          alt="family resort in neral"
                          title=""
                        />
                    
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ---------------------------about section------------------ */}
      <section className="about-section">
        <div className="pattern-bottom" />
        <span className="dotted-pattern dotted-pattern-1" />
        <span className="tri-pattern tri-pattern-1" />
        <div className="auto-container">
          {/*Filters Section*/}
          <div className="container">
            <div className="row clearfix">
              {/*Text Column*/}
              <div className="text-column col-lg-6 col-md-12 col-sm-12">
                <div className="inner">
                  <div className="sec-title">
                    <h2>
                      Quality <br />
                      Holidays With Us
                    </h2>
                  </div>
                  <div className="text">
                    Explore our world-class amenities, savor gourmet dining, and
                    experience the epitome of coastal luxury. Your unforgettable
                    retreat begins here.
                  </div>
                  <div className="link-box">
                    <Link to="/best-agro-tourism-resort-neral" className="theme-btn btn-style-one">
                      <span className="btn-title">View Rooms</span>
                    </Link>
                  </div>
                </div>
              </div>
              {/*Image Column*/}
              <div className="image-column col-lg-6 col-md-12 col-sm-12">
                <div
                  className="inner wow fadeInLeft"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <div className="image-box">
                    <span className="dotted-pattern dotted-pattern-2" />
                    <figure className="image">
                      <img src={abt} alt="beach resort in neral" title="" />
                    </figure>
                    <div className="cap">
                      <span className="txt">
                        Neel <br />
                        Agro <br />
                        Tourism  <br />
                        & Resort
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--Facts Section--> */}
      <section className="facts-section">
        <span className="dotted-pattern dotted-pattern-3" />
        <div className="left-bottom-image">
          <img src={umbrella} alt="umbrella" title="" />
        </div>
        <div className="container">
          <div className="row clearfix">
            {/*Title Column*/}
            <div className="title-column col-xl-7 col-lg-6 col-md-12 col-sm-12">
              <div className="inner">
                <div className="sec-title">
                  <h2>
                    Always enhances <br />
                    your holiday experience.
                  </h2>
                  <div className="lower-text">
                    Our best resort in neral offers unparalleled hospitality,
                    ensuring your escape is filled with relaxation and
                    indulgence.
                  </div>
                </div>
              </div>
            </div>
            {/*Facts Column*/}
            <div className="facts-column col-xl-5 col-lg-6 col-md-12 col-sm-12">
              <div className="inner">
                <div className="facts">
                  <div className="fact-block">
                    <div className="fact-inner">
                      <div
                        className="fact-count wow zoomInStable"
                        data-wow-delay="0ms"
                        data-wow-duration="2000ms"
                      >
                        <div className="count-box counted">
                          <span
                            className="count-text"
                            data-stop={25}
                            data-speed={2000}
                          >
                            <img src={pool} alt="swimming pool" />
                          </span>
                        </div>
                      </div>
                      <h4>Swimming Pool</h4>
                      <div className="text">
                        Dive into excitement with our state-of-the-art swimming
                        pool
                      </div>
                    </div>
                  </div>
                  <div className="fact-block">
                    <div className="fact-inner">
                      <div
                        className="fact-count wow zoomInStable"
                        data-wow-delay="0ms"
                        data-wow-duration="2000ms"
                      >
                        <div className="count-box counted">
                          <span className="count-text">
                            <img src={rain} alt="rain dance" />
                          </span>
                        </div>
                      </div>
                      <h4>Rain Dance</h4>
                      <div className="text">
                        Let loose in our thrilling rain dance area.
                      </div>
                    </div>
                  </div>
                  <div className="fact-block">
                    <div className="fact-inner">
                      <div
                        className="fact-count wow zoomInStable"
                        data-wow-delay="0ms"
                        data-wow-duration="2000ms"
                      >
                        <div className="count-box counted">
                          <span className="count-text">
                            <img src={dance} alt="dance" />
                          </span>
                        </div>
                      </div>
                      <h4>DJ dance</h4>
                      <div className="text">
                        Groove to the beats of our vibrant DJ
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--Rooms Section--> */}
      <section className="rooms-section-one">
        <div className="container">
          <div className="auto-container">
            <div className="sec-title centered">
              <h2>Room &amp; Hall</h2>
              <div className="lower-text">
                Our rooms in the best agro tourism resort in neral are designed
                to provide a seamless blend of comfort and sophistication,
                offering a haven of relaxation amidst stunning surroundings.
                Immerse yourself in the beauty of nature while enjoying the
                modern amenities our rooms have to offer. Whether you seek a
                romantic getaway, a family retreat, or a solo adventure, our
                resort rooms are crafted to cater to your every need.
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="column col-xl-4 col-lg-6 col-md-6 col-sm-12">
              <div
                className="room-block-one height-one wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <div className="image-box">
                    <div
                      className="image-layer"
                      style={{ backgroundImage: `url(${couple})` }}
                    />
                    <figure className="image">
                      <img src="images/gallery/1.jpg" alt="" title="" />
                    </figure>
                  </div>
                  <div className="cap-box">
                    <div className="cap-inner">
                      <h5>Couple Room</h5>
                    </div>
                  </div>
                  <div className="hover-box">
                    <div className="hover-inner">
                      <h4>Couple Room</h4>

                      <div className="text">
                        Discover comfort and style in our Couple Rooms, perfect
                        for romantic getaways.
                      </div>
                      <div className="link-box">
                        <Link to="/top-resort-neral"
                          className="theme-btn btn-style-one"
                        >
                          <span className="btn-title">Enquire Now</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="room-block-one height-one wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <div className="image-box">
                    <div
                      className="image-layer"
                      style={{ backgroundImage: `url(${deluxe})` }}
                    />
                    <figure className="image">
                      <img src="images/gallery/2.jpg" alt="" title="" />
                    </figure>
                  </div>
                  <div className="cap-box">
                    <div className="cap-inner">
                      <h5>Deluxe Room</h5>
                    </div>
                  </div>
                  <div className="hover-box">
                    <div className="hover-inner">
                      <h4>Deluxe Room</h4>

                      <div className="text">
                        Experience luxury in our Deluxe Rooms, designed for a
                        lavish stay.
                      </div>
                      <div className="link-box">
                      <Link to="/top-resort-neral"
                          className="theme-btn btn-style-one"
                        >
                          <span className="btn-title">Enquire Now</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column col-xl-4 col-lg-6 col-md-6 col-sm-12">
              <div
                className="room-block-one height-two wow fadeInUp"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <div className="image-box">
                    <div
                      className="image-layer"
                      style={{ backgroundImage: `url(${dormitory})` }}
                    />
                    {/* <figure className="image">
                <img src={dormitory} alt="" title="" />
              </figure> */}
                  </div>
                  <div className="cap-box">
                    <div className="cap-inner">
                      <h5>Dormitory Room</h5>
                    </div>
                  </div>
                  <div className="hover-box">
                    <div className="hover-inner">
                      <h4>Dormitory Room</h4>

                      <div className="text">
                        Our Dormitory Rooms offer a budget-friendly option for
                        group travelers.
                      </div>
                      <div className="link-box">
                      <Link to="/top-resort-neral"
                          className="theme-btn btn-style-one"
                        >
                          <span className="btn-title">Enquire now</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column col-xl-4 col-lg-6 col-md-6 col-sm-12">
              <div
                className="room-block-one height-one wow fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <div className="image-box">
                    <div
                      className="image-layer"
                      style={{ backgroundImage: `url(${family})` }}
                    />
                    {/* <figure className="image">
                      <img src="images/gallery/4.jpg" alt="" title="" />
                    </figure> */}
                  </div>
                  <div className="cap-box">
                    <div className="cap-inner">
                      {/* <div className="price">
                  From <span>$50.00</span>
                </div> */}
                      <h5>Family Room</h5>
                    </div>
                  </div>
                  <div className="hover-box">
                    <div className="hover-inner">
                      <h4>Family Room</h4>
                      {/* <div className="pricing clearfix">
                  <div className="price">
                    From <span>$50.00</span>
                  </div>
                  <div className="rating">
                    <span className="fa fa-star" />
                    <span className="fa fa-star" />
                    <span className="fa fa-star" />
                    <span className="fa fa-star" />
                    <span className="fa fa-star" />
                  </div>
                </div> */}
                      <div className="text">
                        Families will find spacious accommodations in our Family
                        Rooms.
                      </div>
                      <div className="link-box">
                      <Link to="/top-resort-neral"
                          className="theme-btn btn-style-one"
                        >
                          <span className="btn-title">Enquire Now</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
               <div
                className="room-block-one height-one wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <div className="image-box">
                    <div
                      className="image-layer"
                      style={{ backgroundImage: `url(${event})` }}
                    />
                    {/* <figure className="image">
                      <img src="images/gallery/5.jpg" alt="" title="" />
                    </figure> */}
                  </div>
                  <div className="cap-box">
                    <div className="cap-inner">
                      <h5>Event Hall</h5>
                    </div>
                  </div>
                  <div className="hover-box">
                    <div className="hover-inner">
                      <h4>Event Hall</h4>

                      <div className="text">
                        Need event space? Our Event Hall provides a versatile
                        venue for special occasions. Embrace relaxation and
                        memorable moments at our resort.
                      </div>
                      <div className="link-box">
                      <Link to="/top-resort-neral"
                          className="theme-btn btn-style-one"
                        >
                          <span className="btn-title">Enquire Now</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="column col-xl-4 col-lg-6 col-md-6 col-sm-12">
             
            </div> */}
          </div>
        </div>
      </section>
      <section className="offers-section-one">
        <div className="auto-container">
          <div className="upper-box clearfix">
            <div className="sec-title">
              <h2>
                Restaurant <br />
                Specialities
              </h2>
              <div className="lower-text">
                Indulge your palate at our resort's restaurant, where culinary
                excellence meets scenic views. Immerse yourself in a gastronomic
                journey, where each bite tells a story of passion and
                creativity. Welcome to a dining experience that transcends
                expectations in the heart of our resort.
              </div>
            </div>
            <div className="link-box">
              <Link to="/Gallery" className="theme-btn btn-style-one">
                <span className="btn-title">View our gallery</span>
              </Link>
            </div>
          </div>
          <div className="row clearfix">
            <div className="offers-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner">
                <div className="offer-block">
                  <div className="offer-inner">
                    <div className="fact-thumb">
                      <div className="image">
                        <img src={chef} alt="" title="" />
                      </div>
                      {/* <span className="iconImg">
                 <img src="" alt=""/>
                </span> */}
                    </div>
                    <div className="price">
                      Perfect <span>Taste</span>
                    </div>
                    <h4>Experienced Chefs</h4>
                    <div className="text">
                      Our chefs craft a diverse menu, blending local flavors
                      with international flair.
                    </div>
                   
                  </div>
                </div>
                <div className="offer-block">
                  <div className="offer-inner">
                    <div className="fact-thumb">
                      <div className="image">
                        <img src={delicious} alt="food" title="" />
                      </div>
                    </div>
                    <div className="price">
                      Variety <span>Dishes</span>
                    </div>
                    <h4>Delicious Food</h4>
                    <div className="text">
                      Savor delectable dishes made from fresh, high-quality
                      ingredients.
                    </div>
                   
                  </div>
                </div>
                <div className="offer-block">
                  <div className="offer-inner">
                    <div
                      className="fact-thumb wow zoomInStable"
                      data-wow-delay="600ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="image">
                        <img src={serv} alt="food serve" title="" />
                      </div>
                    </div>
                    <div className="price">
                      Quality <span>Service</span>
                    </div>
                    <h4>Perfect Dinning</h4>
                    <div className="text">
                      Whether it's a romantic dinner or a family celebration,
                      our restaurant offers an inviting ambiance.
                    </div>
                 
                  </div>
                </div>
              </div>
            </div>
            <div className="images-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner">
                <span className="dotted-pattern dotted-pattern-4" />
                <div className="images">
                  <div className="image">
                    <img src={fimage1} alt="restro" title="" />
                  </div>
                  <div
                    className="image wow fadeInRight"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <img src={sprouts} alt="sprouts plate" title="" />
                  </div>
                  <div
                    className="image wow fadeInRight"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <img src={food} alt="salad plate" title="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--Video Section--> */}
      <section className="video-section">
        <div
          className="image-layer"
          style={{ backgroundImage: `url(${vimage})` }}
        />
        <div className="auto-container">
          <div className="content-box">
            <h2>See The Experience Before You Feel it.</h2>
            {/* <div className="play-link">
        <a
          href="https://www.youtube.com/watch?v=Get7rqXYrbQ"
          className="lightbox-image"
        >
          <span className="icon flaticon-play-button-3" />
        </a>
      </div> */}
          </div>
        </div>
      </section>
      <section className="testimonials-section">
        {/* <span className="dotted-pattern dotted-pattern-3" />
  <span className="tri-pattern tri-pattern-2" /> */}
        <div className="auto-container">
          <div className="sec-title centered">
            <h2>What People Say?</h2>
            <div className="lower-text">
              Guests rave about our best resort in neral !They praise the
              impeccable service, luxurious accommodations, and breathtaking
              surroundings. Many commend our attentive staff for creating a warm
              and welcoming atmosphere.
            </div>
          </div>
          <Swiper
            breakpoints={{
              570: {
                slidesPerView: 1,
                spaceBetween: 70,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            className="mySwiper1"
          >
            <SwiperSlide>
              <div className="inner">
                <div className="content-box">
                  <div className="content">
                    <div className="quote-icon">
                      <span className="flaticon-quote-1" />
                    </div>
                    <div className="text">
                      Rooms are best… Food taste is really superb specially non
                      veg. Pool size is big, rain dance is also big. There are
                      all kind of rooms for family, couples, and groups. Best
                      place to visit
                    </div>
                  </div>
                </div>
                <div className="info">
                  <div className="name">Swanand Gadade Vlogs</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="inner">
                <div className="content-box">
                  <div className="content">
                    <div className="quote-icon">
                      <span className="flaticon-quote-1" />
                    </div>
                    <div className="text">
                      It was nice place to chill with family. Food was very
                      delicious. Excellent staff service. Bedrooms were so
                      cleaned. Great place
                    </div>
                  </div>
                </div>
                <div className="info">
                  <div className="name">GURVINDER KAUR PADDA</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="inner">
                <div className="content-box">
                  <div className="content">
                    <div className="quote-icon">
                      <span className="flaticon-quote-1" />
                    </div>
                    <div className="text">
                      This was our second visit to this resort. The ambience is
                      awesome. Rooms are clean. The property is so big so even
                      if the resort is fully booked, there is ample space every
                      group will get. Food taste and quality is good. Owner Mr.
                      Sandeep Chavan is very helpful and service is top class.
                      The resort is well maintained and Suitable for Family as
                      well as group friend.
                    </div>
                  </div>
                </div>
                <div className="info">
                  <div className="name">Vishal K.</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="inner">
                <div className="content-box">
                  <div className="content">
                    <div className="quote-icon">
                      <span className="flaticon-quote-1" />
                    </div>
                    <div className="text">
                      Its a beautiful resort in the lap of nature, with a lush
                      green forest all around, the ambience of the place is just
                      awsome. Staff is very helpful, hospitality is great. A
                      must visit place especially for the nature lovers!!
                    </div>
                  </div>
                </div>
                <div className="info">
                  <div className="name">Shivani kapoor</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="inner">
                <div className="content-box">
                  <div className="content">
                    <div className="quote-icon">
                      <span className="flaticon-quote-1" />
                    </div>
                    <div className="text">
                      Lorem ipsum dolor sit amet consectetur adipis elit eiusmod
                      tempor incidunt sed labore dolore magna.
                    </div>
                  </div>
                </div>
                <div className="info">
                  {/* <div className="image">
      <img src="images/resource/testi-thumb-3.jpg" alt="" />
    </div> */}
                  <div className="name">Dominic Allen</div>
                  <div className="designation">Designer</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="inner">
                <div className="content-box">
                  <div className="content">
                    <div className="quote-icon">
                      <span className="flaticon-quote-1" />
                    </div>
                    <div className="text">
                      Lorem ipsum dolor sit amet consectetur adipis elit eiusmod
                      tempor incidunt sed labore dolore magna.
                    </div>
                  </div>
                </div>
                <div className="info">
                  {/* <div className="image">
      <img src="images/resource/testi-thumb-3.jpg" alt="" />
    </div> */}
                  <div className="name">Dominic Allen</div>
                  <div className="designation">Designer</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="inner">
                <div className="content-box">
                  <div className="content">
                    <div className="quote-icon">
                      <span className="flaticon-quote-1" />
                    </div>
                    <div className="text">
                      Lorem ipsum dolor sit amet consectetur adipis elit eiusmod
                      tempor incidunt sed labore dolore magna.
                    </div>
                  </div>
                </div>
                <div className="info">
                  {/* <div className="image">
      <img src="images/resource/testi-thumb-3.jpg" alt="" />
    </div> */}
                  <div className="name">Dominic Allen</div>
                  <div className="designation">Designer</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="inner">
                <div className="content-box">
                  <div className="content">
                    <div className="quote-icon">
                      <span className="flaticon-quote-1" />
                    </div>
                    <div className="text">
                      Lorem ipsum dolor sit amet consectetur adipis elit eiusmod
                      tempor incidunt sed labore dolore magna.
                    </div>
                  </div>
                </div>
                <div className="info">
                  {/* <div className="image">
      <img src="images/resource/testi-thumb-3.jpg" alt="" />
    </div> */}
                  <div className="name">Dominic Allen</div>
                  <div className="designation">Designer</div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
