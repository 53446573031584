import React, { useState,useLayoutEffect} from "react";
import { useLocation } from "react-router-dom";
import Footer from "../Components/Footer";
import Mybackground from "../Images/pics/page_banner.JPG";
import {Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import "../Css/RoomView.css";
import ServDesc from "../Components/ServDesc";
const RoomDetails = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const search = useLocation();
  const Path = search.pathname;
  const [serviceData, setServiceData] = useState(ServDesc);

  const FData = serviceData.find((e) => e.Slugs == Path);

  return (
    <div>
        <section className="page-banner">
        <div
          className="image-layer"
          style={{ backgroundImage: `url(${Mybackground})` }}
        />
        <div className="banner-bottom-pattern" />
        <div className="banner-inner">
          <div className="auto-container">
            <div className="inner-container clearfix">
              <h1>Rooms</h1>
              <div className="page-nav">
                <ul className="bread-crumb clearfix">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li className="active">Room Details</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* -----------------------------room description-------------------- */}
      <section className="room-single">
      <div className="lower-box">
      <div className="row clearfix">
        <div
          className="image-block col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
          data-wow-delay="0ms"
          data-wow-duration="1500ms"
        >
              <Swiper
              slidesPerView={1}
              spaceBetween={30}
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Pagination]}
              // breakpoints={{
              //   320: {
              //     slidesPerView: 1,
              //     spaceBetween: 20,
              //   },
              //   640: {
              //     slidesPerView: 1,
              //     spaceBetween: 20,
              //   },
              //   768: {
              //     slidesPerView: 2,
              //     spaceBetween: 40,
              //   },
              //   1024: {
              //     slidesPerView: 3,
              //     spaceBetween: 50,
              //   },
              // }}
              pagination={{
                clickable: true,
              }}
              className="mySwiper"
            >
            <SwiperSlide>
            <figure className="image">
            <a
              href="images/resource/featured-image-54.jpg"
              className="lightbox-image"
            >
              <img
                src={FData.ServiceImg}
                alt=""
                title=""
              />
            </a>
          </figure>
      </SwiperSlide>
            
                <SwiperSlide>
                <figure className="image">
            <a
              href="images/resource/featured-image-54.jpg"
              className="lightbox-image"
            >
              <img
                src={FData.ServiceImg1}
                alt=""
                title=""
              />
            </a>
          </figure>
      
            
            </SwiperSlide>
            <SwiperSlide>
                <figure className="image">
            <a
              href="images/resource/featured-image-54.jpg"
              className="lightbox-image"
            >
              <img
                src={FData.ServiceImg2}
                alt=""
                title=""
              />
            </a>
          </figure>
      
            
            </SwiperSlide>
            <SwiperSlide>
                <figure className="image">
            <a
              href="images/resource/featured-image-54.jpg"
              className="lightbox-image"
            >
              <img
                src={FData.ServiceImg3}
                alt=""
                title=""
              />
            </a>
          </figure>
      
            
            </SwiperSlide>
            <SwiperSlide>
                <figure className="image">
            <a
              href="images/resource/featured-image-54.jpg"
              className="lightbox-image"
            >
              <img
                src={FData.ServiceImg4}
                alt=""
                title=""
              />
            </a>
          </figure>
      
            
            </SwiperSlide>
          </Swiper> 
                
      
        </div>
        <div
          className="image-block col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
          data-wow-delay="300ms"
          data-wow-duration="1500ms"
        >
          <div
      className="upper-box wow fadeInUp"
      data-wow-delay="0ms"
      data-wow-duration="1500ms"
    >
      <div className="upper-inner">
        <h2>{FData.ServiceName}</h2>
        <div className="pricing clearfix">
          {/* <div className="price">
            Start From: <span>$50.00</span> / Per Night
          </div> */}
        
        </div>
        <div className="text">
          <p>
           {FData.ServicePara1}
          </p>
          <p>
          {FData.ServicePara2}
          </p>
        </div>
        
      </div>
    </div>
        </div>
      </div>
    </div>
  <div className="circles-two">
    <div className="c-1" />
    <div className="c-2" />
  </div>
  <span className="dotted-pattern dotted-pattern-3" />
  <span className="tri-pattern tri-pattern-3" />
  <div className="auto-container">
   
    <div
      className="details-box wow fadeInUp"
      data-wow-delay="0ms"
      data-wow-duration="1500ms"
    >
      <div className="details-inner">
        <h3>Room Amenities</h3>
        <div className="text">
         {FData.ServicePara3}
        </div>
        <ul className="info clearfix">
          <li>
            <span className="icon flaticon-tv" /> Telivision
          </li>
          <li>
            <span className="icon flaticon-wifi" /> Wi-Fi
          </li>
          <li>
            <span className="icon flaticon-coffee-cup" /> Wardrobe
          </li>
          <li>
            <span className="icon flaticon-bathtub" /> Blankets
          </li>
          <li>
            <span className="icon flaticon-stones" /> Side table
          </li>
          <li>
            <span className="icon flaticon-newspaper" /> Coffee Maker
          </li>
          <li>
            <span className="icon flaticon-wine-glass" /> Mini Fridge
          </li>
          <li>
            <span className="icon flaticon-dumbbell" /> Tea/Coffee
          </li>
        </ul>
      </div>
    </div>
   
  </div>
</section>
{/* ----------------------------------Room Images------------------- */}


      <Footer/>
    </div>
  )
}

export default RoomDetails