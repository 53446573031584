import React, {useLayoutEffect} from "react";
import Mybackground from "../Images/pics/page_banner.JPG";
import Footer from "../Components/Footer";

import {Link} from "react-router-dom"
import ServDesc from "../Components/ServDesc";
import { MetaTags } from 'react-meta-tags';
const Rooms = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      {/* ========= SEO ========== */}

    <MetaTags>
        <title>
        Best Agro Tourism Resort in Neral | Neel Agro Tourism & Resort 
        </title>
        <meta name="title" title="Best Agro Tourism Resort in Neral | Neel Agro Tourism & Resort "/>
        <meta
          name="description"
          content="Experience the perfect blend of agriculture and leisure at the Best Agro Tourism Resort in Neral. Immerse yourself in nature's bounty and enjoy."
        />
         <meta
          property="og:title"
          content="Best Agro Tourism Resort in Neral | Neel Agro Tourism & Resort "
        />
        <meta
          property="og:description"
          content="Experience the perfect blend of agriculture and leisure at the Best Agro Tourism Resort in Neral. Immerse yourself in nature's bounty and enjoy."
        />
        <meta
          name="keywords"
          content="Best Resort in Neral,
          Resort Near Neral,
          Best Agro Tourism Resort in Neral,
          Couple Friendly Resort in Neral,
          Resort in Neral with Dormitory Room,
          Best Resort in Neral For Family,
          Resort in Neral For Stay,
          Top Resort in Neral"
        />

        <meta property="article:tag" content="Best Resort in Neral" />
        <meta property="article:tag" content="Resort Near Neral" />
        <meta property="article:tag" content="Couple Friendly Resort in Neral" />
   <link rel="canonical" href="/best-agro-tourism-resort-nerallqqqhqq" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Best Agro Tourism Resort in Neral | Neel Agro Tourism & Resort " />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="" />
       
        
      </MetaTags>

      {/* ======== Seo end ======= */}
      <section className="page-banner">
        <div
          className="image-layer"
          style={{ backgroundImage: `url(${Mybackground})` }}
        />
        <div className="banner-bottom-pattern" />
        <div className="banner-inner">
          <div className="auto-container">
            <div className="inner-container clearfix">
              <h1 className="d-none">Best Agro Tourism Resort in Neral | Neel Agro Tourism & Resort </h1>
             <h2>Rooms</h2>
              <div className="page-nav">
                <ul className="bread-crumb clearfix">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li className="active">Rooms Grid</li>
                  <p className="d-none">
Our rooms in the best agro tourism resort in neral  are designed to provide a seamless blend of comfort and sophistication, offering a haven of relaxation amidst stunning surroundings. Immerse yourself in the beauty of nature while enjoying the modern amenities our rooms have to offer. Whether you seek a romantic getaway, a family retreat, or a solo adventure, our resort rooms are crafted to cater to your every need.
</p>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* -------------------------rooms section---------------------------- */}
      <section className="rooms-section">
        <div className="auto-container">
         
          <div className="row clearfix">
          {ServDesc.map((elem) => {
              const {id,Slugs, ServiceName,ServiceImg,ServicePara1} =
                elem;
              return (
                <>
            <div
              className="room-block-two col-lg-6 col-md-6 col-sm-12 wow fadeInUp" key={id}
              
            >
              <div className="inner-box">
            
              
            <div className="image-box">
                  <figure className="image">

                      <img
                        src={ServiceImg}
                        alt="deluxe-resort-neral"
                        title=""
                      />
              
                  </figure>
                </div>
      
            
         
                
                <div className="lower-box">
                  <h4>{ServiceName}</h4>
                  <div className="pricing clearfix">
                 
                  
                  </div>
                  <div className="text">
               
               { ServicePara1}</div>
                  <div className="link-box">
                  <Link 
                       to={Slugs}
                      className="theme-btn btn-style-three"
                    >
                      <span className="btn-title">
                        View Details
                        </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            </>
       );
     })}
            
           
          </div>
     
      </div>
      </section>
      <Footer />
    </div>
  );
};

export default Rooms;
