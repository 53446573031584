// -------------couple-------------------------
import couple from "../Images/gallery/double_bed_room.JPG";
import couple1 from "../Images/gallery/couple1.jpg";
import couple2 from "../Images/gallery/couple2.jpg";
import couple3 from "../Images/gallery/couplebath.jpg";
import couple4 from "../Images/gallery/couplebath3.jpg";
// -----------------dormitory--------------------
import dormitory from "../Images/pics/dormitory.JPG";
import d1 from "../Images/pics/dormitory/WhatsApp Image 2023-12-02 at 2.05.12 PM (1).jpeg";
import d2 from "../Images/pics/dormitory/WhatsApp Image 2023-12-02 at 2.05.12 PM.jpeg";
import d3 from "../Images/pics/dormitory/WhatsApp Image 2023-12-02 at 2.05.13 PM (2).jpeg";
import d4 from "../Images/pics/dormitory/WhatsApp Image 2023-12-02 at 2.05.13 PM (3).jpeg";
import family from "../Images/pics/family.JPG";
import deluxe  from "../Images/pics/deluxe.JPG";
const ServDesc = [

  {
    id: 1,
    
    // ServiceIcon: icon_cd,
    ServiceName: "Couple Room",
    ServicePara1:
      "Escape to our beachfront paradise designed exclusively for couples. Our couple friendly resort in neral is best for you .Nestled along the shoreline, our resort offers a blend of luxury and intimacy. Each couple's room is a sanctuary of comfort with stunning sea views, ensuring a romantic retreat. Immerse yourselves in the soothing sounds of the waves and the gentle ocean breeze. ",

    ServicePara2:
      " Our attentive staff is dedicated to enhancing your experience, from personalized services to curated dining options. Explore pristine beaches hand in hand, indulge in spa treatments, and savor exquisite cuisine. Whether celebrating a special occasion or seeking a quiet getaway, our beach resort promises an unforgettable escape for two. Discover a world where love and luxury meet, creating cherished moments that linger long after the sun sets over the horizon.",
    ServicePara3:
      "With revolutionary technology and expertise, it is very much possible. ",
        ServiceImg: couple,
        ServiceImg1: couple1,
         ServiceImg2: couple2,
        ServiceImg3: couple3,
        ServiceImg4: couple4,
    MetaTitle: "Couple Friendly Resort in Neral | Neel Agro Tourism & Resort ",
    MetaDes: "Discover the perfect retreat for couples at our Couple Friendly Resort in Neral. Immerse yourselves in the serene ambiance, and luxurious amenities.",
    CanonicalTag: "couple-friendly-resort-neral",
    Slugs:"/couple-friendly-resort-neral",
  },

  {
    id: 2,
    ServiceName: "Dormitory Rooms",
    ServicePara1:
      "Discover comfort and camaraderie in our resort in neral with dormitory rooms. Ideal for group travelers and adventurers, our dorms offer a vibrant community atmosphere paired with the convenience of resort amenities. Each dormitory is designed to foster a sense of togetherness, with shared spaces that encourage interaction and bonding.",

    ServicePara2:
      "Welcome to our beach resort, where budget-friendly comfort meets the beauty of the shoreline. Our dormitory rooms provide a vibrant and communal atmosphere, ideal for budget-conscious travelers seeking a shared beachfront experience. Nestled by the sea, our resort invites guests to unwind and connect.",
    ServicePara3:
      "Each dormitory room is thoughtfully designed for shared living, offering a balance of affordability and convenience. Embrace the spirit of camaraderie as you make new friends in a laid-back coastal setting. Our resort in neral with dormitory rooms boasts not only affordable accommodations but also a range of recreational activities, from beach sports to bonfires under the stars. The vibrant energy of the dormitory is complemented by the tranquil surroundings, creating a unique and memorable beach retreat. Join fellow travelers in creating lasting memories and embark on a coastal adventure where the sun, sand, and shared moments blend seamlessly for an affordable beachfront getaway.",
    ServicePara4:
      "",
    ServiceImg: dormitory,
    ServiceImg1:d1,
    ServiceImg2: d2,
   ServiceImg3: d3,
   ServiceImg4: d4,
    MetaTitle: "Resort in Neral with Dormitory Room | Neel Agro Tourism",
    MetaDes: "Experience affordable group accommodations at our Resort in Neral with a Dormitory Room. Ideal for large gatherings, our dormitory offers comfort.",
    CanonicalTag: "resort-neral-dormitory-room",
    Slugs:"/resort-neral-dormitory-room",
  },
  {
    id: 3,
    ServiceName: "Family Room",
    ServicePara1:
      "Our best resort in neral for family Designed with families in mind, our spacious accommodations provide a perfect blend of relaxation and recreation. Create lasting memories with your loved ones in rooms that cater to the unique needs of families. From kid-friendly amenities to ample space for bonding, our family rooms offer a home-like atmosphere amidst the beauty of our resort.",

    ServicePara2:
      "Welcome to our family-friendly resort, where cherished moments and comfort converge. Our best resort in neral for family  are designed to accommodate the unique needs of your loved ones, providing a cozy haven for unforgettable vacations. Nestled in a scenic locale, our resort offers a perfect blend of relaxation and recreation.",
    ServicePara3:
      " Our spacious family rooms ensure everyone has their own space while remaining close-knit. Enjoy the convenience of in-room amenities and soak in the warmth of our hospitality. From exciting kid-friendly activities to serene spaces for parents, our resort caters to every family member. Delight in delicious meals at our family-friendly restaurants, creating memories over shared meals.With a focus on creating a wholesome experience, our dedicated staff is committed to making your family getaway truly special. Discover a retreat where laughter echoes through the corridors, and the joy of togetherness is celebrated against the backdrop of sun-kissed beaches. Experience the perfect blend of comfort and adventure as your family creates lasting memories in our welcoming resort.",
    ServicePara4:
      "",
    ServiceImg:family,
    ServiceImg1:family,
    ServiceImg2:family,
    ServiceImg3:family,
    ServiceImg4:family,
    MetaTitle: "Best Resort in Neral For Family | Neel Agro Tourism & Resort ",
    MetaDes: "Create cherished family moments at the Best Resort in Neral for family vacations. Our resort offers a perfect blend of comfort, entertainment, and tranquility.",
    CanonicalTag: "best-resort-neral-family-vacations",
    Slugs:"/best-resort-neral-family-vacations",
  },
  {

    id: 4,
    ServiceName: "Deluxe Room",
    ServicePara1:
      " Welcome to our agro resort paradise, where luxury meets the serenity of nature. Our deluxe rooms redefine countryside indulgence, providing a refined escape for those seeking an elevated agro retreat experience. Each meticulously crafted room is a sanctuary of comfort and style, boasting breathtaking views of the lush landscapes and azure skies.",

    ServicePara2:
      " Immerse yourself in the opulence of our deluxe accommodations, where modern amenities and elegant design seamlessly blend with the natural beauty that surrounds us.",
    ServicePara3:
      "At our resort, we go beyond providing a place to stay; we curate an experience tailored to the discerning traveler. From personalized concierge services to private balconies overlooking the ocean, every detail is designed to enhance your stay. Indulge in gourmet dining at our exquisite restaurants or unwind with a spa treatment overlooking the beach.",
    ServicePara4:
      "",
    ServiceImg:deluxe,
    ServiceImg1:deluxe,
    ServiceImg2:deluxe,
    ServiceImg3:deluxe,
    ServiceImg4:deluxe,
    MetaTitle: "Deluxe Room Resort in Neral | Neel Agro Tourism & Resort ",
    MetaDes: "",
    CanonicalTag: "deluxe-resort-neral",
    Slugs:"/deluxe-resort-neral",
   
  },
  

];

export default ServDesc





