import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar from "./Components/Navbar"
import Home from './Pages/Home';
import About from './Pages/About';
import Rooms from './Pages/Rooms';
import Services from "./Pages/Services";
import RoomDetails from './Pages/RoomDetails';
import Gallery from './Pages/Gallery';
import Contact from './Pages/Contact';
import ServDesc from './Components/ServDesc';
function App() {
  return (
    <BrowserRouter>
    <NavBar/>
      <Routes>
        <Route path='/' element={<Home/>}/> 
         <Route path='/resort-near-neral' element={<About/>}/>
         <Route path='/best-agro-tourism-resort-neral' element={<Rooms/>} />
         <Route path='/resort-neral-stay' element={<Services/>} />
         <Route path='/Gallery' element={<Gallery/>} />
         {ServDesc.map((route, index) => (
          <Route
            key={index}
            exact
            path={route.Slugs}
            element={<RoomDetails />}
          />
        ))}
         <Route path='/top-resort-neral' element={<Contact/>} /> 

       

      </Routes>
    </BrowserRouter>
  );
}

export default App;
