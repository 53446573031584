import React, {useLayoutEffect} from 'react'
import "../Css/Service.css";
import Mybackground from "../Images/pics/page_banner.JPG";
import Footer from "../Components/Footer";
import swim from "../Images/pool2.jpg";
import rain from "../Images/rain dance.jpg";
import park from "../Images/gallery/parking.jpg";
import bonfire from "../Images/bonfire.jpg";
import garden from "../Images/garden.jpg";
import restaurant from "../Images/restaurant.jpg";
import pets from "../Images/gallery/pets.JPG";
import play from "../Images/gallery/garden.JPG";
// import cottage from "../Images/cottage2.jpg";
import bday from "../Images/event decoration.jpg"
import wifi from "../Images/icons/wifi.png";
import waiter from "../Images/icons/room-service.png";
import parking from "../Images/icons/parking.png";
import cctv from "../Images/icons/cctv.png";
import house from "../Images/icons/home.png";
import backup from "../Images/icons/generator.png";
import { MetaTags } from 'react-meta-tags';
const Services = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
 {/* ========= SEO ========== */}

 <MetaTags>
        <title>
        Resort in Neral For Stay | Neel Agro Tourism & Resort 
        </title>
        <meta name="title" title="Resort in Neral For Stay | Neel Agro Tourism & Resort "/>
        <meta
          name="description"
          content="Discover a welcoming haven at our Resort in Neral For a Stay. Whether for leisure or business, experience hospitality at its finest. "
        />
         <meta
          property="og:title"
          content="Resort in Neral For Stay | Neel Agro Tourism & Resort "
        />
        <meta
          property="og:description"
          content="Discover a welcoming haven at our Resort in Neral For a Stay. Whether for leisure or business, experience hospitality at its finest."
        />
        <meta
          name="keywords"
          content="Best Resort in Neral,
          Resort Near Neral,
          Best Agro Tourism Resort in Neral,
          Couple Friendly Resort in Neral,
          Resort in Neral with Dormitory Room,
          Best Resort in Neral For Family,
          Resort in Neral For Stay,
          Top Resort in Neral"
        />

        <meta property="article:tag" content="Best Resort in Neral" />
        <meta property="article:tag" content="Resort Near Neral" />
        <meta property="article:tag" content="Couple Friendly Resort in Neral" />
   <link rel="canonical" href="/resort-near-neral" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Resort in Neral For Stay | Neel Agro Tourism & Resort " />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="" />
       
        
      </MetaTags>

      {/* ======== Seo end ======= */}
<section className="page-banner">
        <div
          className="image-layer"
          style={{ backgroundImage: `url(${Mybackground})` }}
        />
        <div className="banner-bottom-pattern" />
        <div className="banner-inner">
          <div className="auto-container">
            <div className="inner-container clearfix">
              <h1 className="d-none">Resort in Neral For Stay | Neel Agro Tourism & Resort </h1>
              <h2>Services</h2>
              <div className="page-nav">
                <ul className="bread-crumb clearfix">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li className="active">Service</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="menu-section">
  <div className="pattern-top" />
  <span className="dotted-pattern dotted-pattern-13" />
  <span className="tri-pattern tri-pattern-10" />
  <div className="auto-container">
    <div className="sec-title centered">
      <h2>Featured Facilities</h2>
      <div className="lower-text">
      Welcome to our resort in neral for stay ,where luxury meets tranquility. Nestled in breathtaking surroundings, our facilities are designed to elevate your experience. From indulgent spa treatments to gourmet dining, we strive to provide a haven for relaxation and recreation. At our resort in neral for stay, every detail is crafted to ensure your stay is a seamless blend of comfort and sophistication. Your escape to serenity begins here.

      </div>
    </div>
    <div className="menu-container">
      <span className="dotted-pattern dotted-pattern-14" />
      <div className="row clearfix">
        {/*Block*/}
        <div
          className="menu-item col-lg-6 col-md-12 col-sm-12 wow fadeInUp"
          data-wow-delay="0ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <div className="item-inner">
              <div className="item-price">
                <span>  <img src={backup} alt="power backup"/></span>
              </div>
              <h4>Power Backup</h4>
              <div className="text">
              Ensuring an uninterrupted escape for our guests, our resort is equipped with robust power backup solutions.
              </div>
            </div>
            
          </div>
        </div>
        {/*Block*/}
        <div
          className="menu-item col-lg-6 col-md-12 col-sm-12 wow fadeInUp"
          data-wow-delay="300ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <div className="item-inner">
              <div className="item-price">
                <span><img src={parking} alt="parking"/></span>
              </div>
              <h4>Parking</h4>
              <div className="text">
              We understand the importance of hassle-free experiences, which is why we offer ample and secure parking facilities for our guests.

              </div>
            </div>
        
          </div>
        </div>
        {/*Block*/}
        <div
          className="menu-item col-lg-6 col-md-12 col-sm-12 wow fadeInUp"
          data-wow-delay="0ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <div className="item-inner">
              <div className="item-price">
                <span><img src={wifi} alt="wifi"/></span>
              </div>
              <h4>Free Wifi</h4>
              <div className="text">
              Stay connected seamlessly during your retreat at our resort, where we offer complimentary high-speed Wi-Fi for all our guests.

              </div>
            </div>
           
          </div>
        </div>
        {/*Block*/}
        <div
          className="menu-item col-lg-6 col-md-12 col-sm-12 wow fadeInUp"
          data-wow-delay="300ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <div className="item-inner">
              <div className="item-price">
                <span><img src={waiter} alt="service"/></span>
              </div>
              <h4>Room Service</h4>
              <div className="text">
              Our dedicated team is committed to delivering a seamless and personalized experience right to your doorstep.

              </div>
            </div>
          
          </div>
        </div>
        {/*Block*/}
        <div
          className="menu-item col-lg-6 col-md-12 col-sm-12 wow fadeInUp"
          data-wow-delay="0ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <div className="item-inner">
              <div className="item-price">
                <span><img src={house} alt="hut"/></span>
              </div>
              <h4>House Keeping</h4>
              <div className="text">
              Our housekeeping team is dedicated to ensuring that every corner of your accommodation reflects the highest standards of hygiene and tidiness.
              </div>
            </div>
          
          </div>
        </div>
        {/*Block*/}
        <div
          className="menu-item col-lg-6 col-md-12 col-sm-12 wow fadeInUp"
          data-wow-delay="300ms"
          data-wow-duration="1500ms"
        >
          <div className="inner-box">
            <div className="item-inner">
              <div className="item-price">
                <span><img src={cctv} alt="security"/></span>
              </div>
              <h4>CCTV Survelliance</h4>
              <div className="text">
              We employ cutting-edge CCTV surveillance systems throughout the property to ensure a secure environment for our guests.

              </div>
            </div>
          
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
        {/* ---------------------------features Section------------- */}
        <section className="featured-section-five alt-padd">
  <span className="dotted-pattern dotted-pattern-10" />
  <div className="circles-two">
    <div className="c-1" />
    <div className="c-2" />
  </div>
  <div className="auto-container">
    <div className="sec-title centered">
      <h2>
        Explore More <br />
        You Love It!
      </h2>
      <div className="lower-text">
      Our agrotourism destination is a haven for those in search of the ideal fusion of relaxation and adventure. Delight in farm-fresh produce at our rustic restaurant, unwind amidst the sprawling fields, and let our genuine hospitality redefine your agrotourism retreat. At our agro resort in Neral, every moment is a wave of tranquility and bliss, inviting you to immerse yourself in the serenity of rural life.

      </div>
    </div>
    <div className="row clearfix">
      {/*Block*/}
      <div
        className="featured-block-two col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
        data-wow-delay="0ms"
        data-wow-duration="1500ms"
      >
        <div className="inner-box">
          <div className="image-box">
            <figure className="image">
             
                <img
                  src={bday}
                  alt="birthday"
                  title=""
                />
         
            </figure>
          </div>
          <div className="cap-box">
            <div className="cap-inner">
              {/* <div className="price">
                From <span>$50.00</span>
              </div> */}
              <h5>Events</h5>
            </div>
          </div>
        </div>
      </div>
      {/*Block*/}
      <div
        className="featured-block-two col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
        data-wow-delay="300ms"
        data-wow-duration="1500ms"
      >
        <div className="inner-box">
          <div className="image-box">
            <figure className="image">
             
                <img
                  src={garden}
                  alt="garden"
                  title=""
                />
           
            </figure>
          </div>
          <div className="cap-box">
            <div className="cap-inner">
              {/* <div className="price">
                From <span>$50.00</span>
              </div> */}
              <h5>garden</h5>
            </div>
          </div>
        </div>
      </div>
      {/*Block*/}
      <div
        className="featured-block-two col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
        data-wow-delay="600ms"
        data-wow-duration="1500ms"
      >
        <div className="inner-box">
          <div className="image-box">
            <figure className="image">
             
                <img
                  src={restaurant}
                  alt="restro"
                  title=""
                />
            
            </figure>
          </div>
          <div className="cap-box">
            <div className="cap-inner">
              {/* <div className="price">
                From <span>$50.00</span>
              </div> */}
              <h5>Restaurant</h5>
            </div>
          </div>
        </div>
      </div>
        {/*Block*/}
        <div
        className="featured-block-two col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
        data-wow-delay="300ms"
        data-wow-duration="1500ms"
      >
        <div className="inner-box">
          <div className="image-box">
            <figure className="image">
            
                <img
                  src={pets}
                  alt="pets"
                  title=""
                />
             
            </figure>
          </div>
          <div className="cap-box">
            <div className="cap-inner">
              {/* <div className="price">
                From <span>$50.00</span>
              </div> */}
              <h5>Pets</h5>
            </div>
          </div>
        </div>
      </div>
      {/*Block*/}
       {/*Block*/}
       <div
        className="featured-block-two col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
        data-wow-delay="300ms"
        data-wow-duration="1500ms"
      >
        <div className="inner-box">
          <div className="image-box">
            <figure className="image">
            
                <img
                  src={play}
                  alt="playing area"
                  title=""
                />
              
            </figure>
          </div>
          <div className="cap-box">
            <div className="cap-inner">
              {/* <div className="price">
                From <span>$50.00</span>
              </div> */}
              <h5>Child Play Area</h5>
            </div>
          </div>
        </div>
      </div>
      {/*Block*/}
       {/*Block*/}
       <div
        className="featured-block-two col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
        data-wow-delay="300ms"
        data-wow-duration="1500ms"
      >
        <div className="inner-box">
          <div className="image-box">
            <figure className="image">
           
                <img
                  src={bonfire}
                  alt="bonfire"
                  title=""
                />
             
            </figure>
          </div>
          <div className="cap-box">
            <div className="cap-inner">
              {/* <div className="price">
                From <span>$50.00</span>
              </div> */}
              <h5>Bonfire</h5>
            </div>
          </div>
        </div>
      </div>
      {/*Block*/}
       {/*Block*/}
       <div
        className="featured-block-two col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
        data-wow-delay="300ms"
        data-wow-duration="1500ms"
      >
        <div className="inner-box">
          <div className="image-box">
            <figure className="image">
             
                <img
                  src={rain}
                  alt="rain"
                  title=""
                />
            
            </figure>
          </div>
          <div className="cap-box">
            <div className="cap-inner">
              {/* <div className="price">
                From <span>$50.00</span>
              </div> */}
              <h5>Rain Dance</h5>
            </div>
          </div>
        </div>
      </div>
      {/*Block*/}
       {/*Block*/}
       <div
        className="featured-block-two col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
        data-wow-delay="300ms"
        data-wow-duration="1500ms"
      >
        <div className="inner-box">
          <div className="image-box">
            <figure className="image">
             
                <img
                  src={park}
                  alt="parking"
                  title=""
                />
             
            </figure>
          </div>
          <div className="cap-box">
            <div className="cap-inner">
              {/* <div className="price">
                From <span>$50.00</span>
              </div> */}
              <h5>Parking</h5>
            </div>
          </div>
        </div>
      </div>
      {/*Block*/}
       {/*Block*/}
       <div
        className="featured-block-two col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
        data-wow-delay="300ms"
        data-wow-duration="1500ms"
      >
        <div className="inner-box">
          <div className="image-box">
            <figure className="image">
         
                <img
                  src={swim}
                  alt="swimming"
                  title=""
                />
          
            </figure>
          </div>
          <div className="cap-box">
            <div className="cap-inner">
              {/* <div className="price">
                From <span>$50.00</span>
              </div> */}
              <h5>Swimming</h5>
            </div>
          </div>
        </div>
      </div>
      {/*Block*/}
    </div>
  </div>
</section>


<Footer/>

    </div>
  )
}

export default Services