import React,{useLayoutEffect} from 'react'
import "../Css/Footer.css";
import {Link} from "react-router-dom";
// import Logo from "../images/Green Farm.png";
const Footer = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
        <footer className="footer-section">
  <div className="container">
    <div className="footer-cta pt-5 pb-5">
      <div className="row">
        <div className="col-lg-5 col-md-6  mb-30">
          <div className="single-cta">
            <i className="fa fa-map-marker" />
            <div className="cta-text pt-2">
              <h4>Find us</h4>
              <span>
              Neral, Vakas, Maharashtra 410101</span>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-30">
          <div className="single-cta">
            <i className="fa fa-phone" />
            <div className="cta-text pt-2">
              <h4>Call us</h4>
              <span> 9326907820  / 7774080297 </span>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-12 mb-30">
          <div className="single-cta">
            <i className="fa fa-envelope-open" />
            <div className="cta-text pt-2">
              <h4>Mail us</h4>
              <span> resortneel@gmail.com</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-content pt-5 pb-5">
      <div className="row">
        <div className="col-xl-4 col-lg-4 mb-50">
          <div className="footer-widget">
            <div className="footer-logo">
              <a href="index.html">
                {/* <img
                  src={Logo}
                  className="img"
                  alt="logo"
                /> */}
              </a>
            </div>
            <div className="footer-text">
              <p>
              Located in Neral, 10 miles from Bhivpuri Waterfalls, NEEL AGRO TOURISM &RESORT provides accommodations with a garden and free private parking. The resort features an indoor pool, room service and free WiFi.
              </p>
            </div>
                <div className="footer-widget">
            {/* <div className="footer-widget-heading">
              <h3></h3>
            </div> */}
          
          
          </div>
           
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
          <div className="footer-widget">
            <div className="footer-widget-heading">
              <h3>Useful Links</h3>
            </div>
            <ul className="m-0 p-0">
              <li>
                <Link to="/">Home</Link>
              </li>
             
             
           
              <li>
                <Link to="/resort-near-neral">About Us</Link>
              </li>
             
              <li>
                <Link to="/resort-neral-stay">Services</Link>
              </li>
              <li>
                <Link to="/best-agro-tourism-resort-neral">Rooms</Link>
              </li>


              <li>
                <Link to="/Gallery">Gallery</Link>
              </li>
              <li>
                <Link to="/top-resort-neral">Contact Us </Link>
              </li>
            </ul>
          </div>
      
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
          <div className="footer-widget">
            <div className="footer-widget-heading">
              <h3>Address</h3>
            </div>
            <div className="footer-text mb-25">
              <p>
              Neral, Vakas, Maharashtra 410101
              </p>
            </div>
            {/* <div className="footer-social-icon mt-lg-5">
              <span>Follow us</span>
              <a href="#">
                <i className="fa fa-facebook-f facebook-bg" />
              </a>
              <a href="#">
                <i className="fa fa-twitter twitter-bg" />
              </a>
              <a href="#">
                <i className="fab fa-google-plus-g google-bg" />
              </a>
            </div> */}
          </div>
      
        </div>
      </div>
    </div>
  </div>
  <div className="copyright-area">
    <div className="container">
      <div className="row">
        <div className="col-xl-6 col-lg-6 text-center text-lg-left">
          <div className="copyright-text">
            <p>
              Copyright © 2023, All Right Reserved{" "}
              <br/>
              <a href="">Neel Agro Tourism & Resort</a>
            </p>
            <p>
              Developed By &nbsp;
              <a href="https://skdm.in/">Shree Krishna Digital Marketing</a>
            </p>
          </div>
        </div>
      
        </div>
      </div>
    </div>
  

</footer>

<div className="floattingButton">
  {/* <a href="#" className="float" id="menu-share">
    <i className="fa fa-link my-float" />
  </a> */}
  <ul>
    <li>
      <a href="https://www.instagram.com/neelagrotourism/">
        <i className="fa fa-instagram my-float" />
      </a>
    </li>
    <li>
      <a href="https://facebook.com/theneelsresort">
        <i className="fa fa-facebook my-float" />
      </a>
    </li>
  </ul>
</div>
    </div>
  )
}

export default Footer