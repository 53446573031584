import React, {useLayoutEffect} from "react";
import "../Css/About.css";
import Mybackground from "../Images/pics/page_banner.JPG";
import featured3 from "../Images/gallery/cottage.JPG";
import featured2 from "../Images/gallery/dinning_paasage.JPG";
import featured4 from "../Images/gallery/cottagefront.JPG";

import swim from "../Images/gallery/pool.JPG";
import games from "../Images/carrom.jpg";
import rain from "../Images/rain dance.jpg";
import Footer from "../Components/Footer";
import { MetaTags } from 'react-meta-tags';
const About = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
    {/* ========= SEO ========== */}

    <MetaTags>
        <title>
        Resort near Neral | Neel Agro Tourism & Resort 
        </title>
        <meta name="title" title="Resort near Neral | Neel Agro Tourism & Resort"/>
        <meta
          name="description"
          content="Escape the hustle to find tranquility at our Resort near Neral. Immerse yourself in serene surroundings and create memories of a peaceful getaway."
        />
         <meta
          property="og:title"
          content="Resort near Neral | Neel Agro Tourism & Resort"
        />
        <meta
          property="og:description"
          content="Escape the hustle to find tranquility at our Resort near Neral. Immerse yourself in serene surroundings and create memories of a peaceful getaway."
        />
        <meta
          name="keywords"
          content="Best Resort in Neral,
          Resort Near Neral,
          Best Agro Tourism Resort in Neral,
          Couple Friendly Resort in Neral,
          Resort in Neral with Dormitory Room,
          Best Resort in Neral For Family,
          Resort in Neral For Stay,
          Top Resort in Neral"
        />

        <meta property="article:tag" content="Best Resort in Neral" />
        <meta property="article:tag" content="Resort Near Neral" />
        <meta property="article:tag" content="Couple Friendly Resort in Neral" />
   <link rel="canonical" href="/resort-near-neral" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Best Resort in Neral | Neel Agro Tourism & Resort " />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="" />
       
        
      </MetaTags>

      {/* ======== Seo end ======= */}

      <section className="page-banner">
        <div
          className="image-layer"
          style={{ backgroundImage: `url(${Mybackground})` }}
        />
        <div className="banner-bottom-pattern" />
        <div className="banner-inner">
          <div className="auto-container">
            <div className="inner-container clearfix">
              <h1 className="d-none">Resort near Neral | Neel Agro Tourism & Resort </h1>
              <h2>About Us</h2>
              <div className="page-nav">
                <ul className="bread-crumb clearfix">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li className="active">About us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ---------------About section------------------- */}
      <section className="featured-section-four about-page">
        <span className="dotted-pattern dotted-pattern-11" />
        <div className="circles-two">
          <div className="c-1" />
          <div className="c-2" />
        </div>
        <div className="auto-container">
          <div className="row clearfix">
            {/*Text Column*/}
            <div className="text-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner">
                <div className="sec-title">
                  <h2>
                    Relax in <br />
                    Our Luxury Resort
                  </h2>
                </div>
                <div className="text">
                Welcome to our Farm Resort near Neral, where the gentle rustle of leaves meets the vibrant colors of cultivated fields. Experience the perfect fusion of rural tranquility and agrarian charm. At our resort near Neral, we offer a distinctive retreat, harmonizing the calmness of the countryside with the richness of agricultural landscapes. Savor the wholesome flavors of our farm-to-table culinary offerings, and let the fresh breeze from the fields accompany your immersion into nature's embrace.

                </div>
                <ul className="features">
                  <li>Nestled amidst lush greenery, our farm resort provides a serene getaway with picturesque views of cultivated lands. Immerse yourself in nature with our unique blend of agriculture and countryside luxury.</li>

<li>
Immerse yourself in nature with our unique blend of agriculture and countryside luxury.</li>
<li>
Embrace the natural beauty of the farm as you unwind and connect with the earth in this idyllic setting.</li>
                  
                </ul>
              </div>
            </div>
            {/*Image Column*/}
            <div className="image-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner">
                <span className="dotted-pattern dotted-pattern-10" />
                <div className="image-box clearfix">
                  <figure
                    className="image wow fadeInUp"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <img src={featured2} alt="resort-stay-neral" title="" />
                  </figure>
                  <figure
                    className="image wow fadeInRight"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <img src={featured3} alt="stay at neel resort" title="" />
                  </figure>
                  <figure
                    className="image wow fadeInLeft"
                    data-wow-delay="300ms"
                    data-wow-duration="1500ms"
                  >
                    <img src={featured4} alt="cottage" title="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="facts-section-two alternate">
        <div className="circles">
          <div className="c-1" />
          <div className="c-2" />
        </div>
        <div className="auto-container">
          <div className="sec-title">
            <h2>
              Always Ready <br />
              for your service
            </h2>
            <div className="lower-text">
            Indulge in the farm-fresh delights of our culinary offerings. Your stay with us is not just a vacation. you will find luxury like home here.

            </div>
          </div>
          {/*Facts Column*/}
          <div className="facts-box">
            <div className="row clearfix">
              <div className="fact-block col-lg-4 col-md-6 col-sm-12">
                <div className="fact-inner">
                  <div
                    className="fact-count wow zoomInStable"
                    data-wow-delay="0ms"
                    data-wow-duration="2000ms"
                  >
                    <div className="count-box counted">
                      <span
                        className="count-text"
                        data-stop={25}
                        data-speed={2000}
                      >
                        25
                      </span>
                      +
                    </div>
                  </div>
                  <h4>Working Staff</h4>
                  <div className="text">With a shared passion for hospitality, we thrive on teamwork, professionalism, and a genuine desire to exceed expectations.
</div>
                </div>
              </div>
              <div className="fact-block col-lg-4 col-md-6 col-sm-12">
                <div className="fact-inner">
                  <div
                    className="fact-count wow zoomInStable"
                    data-wow-delay="300ms"
                    data-wow-duration="2000ms"
                  >
                    <div className="count-box counted">
                      <span
                        className="count-text"
                        data-stop={712}
                        data-speed={5000}
                      >
                        20
                      </span>
                    </div>
                  </div>
                  <h4>Amenities</h4>
                  <div className="text">We offer a unique getaway, marrying the serenity of the nature with the richness of agricultural landscapes.
</div>
                </div>
              </div>
              <div className="fact-block col-lg-4 col-md-6 col-sm-12">
                <div className="fact-inner">
                  <div
                    className="fact-count wow zoomInStable"
                    data-wow-delay="600ms"
                    data-wow-duration="2000ms"
                  >
                    <div className="count-box counted">
                      <span
                        className="count-text"
                        data-stop={310}
                        data-speed={4000}
                      >
                        24/7
                      </span>
                    </div>
                  </div>
                  <h4>Service</h4>
                  <div className="text">Where dedicated individuals come together to create exceptional experiences for our guests.

</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* -----------------------------------History Section---------------------- */}
      <section className="history-section">
        <span className="dotted-pattern dotted-pattern-6" />
        <span className="tri-pattern tri-pattern-5" />
        <div className="circles">
          <div className="c-1" />
          <div className="c-2" />
        </div>
        <div className="auto-container">
          <div className="sec-title centered">
            <h2>Activities</h2>
            <div className="lower-text">
            Welcome to our resort near Neral, where relaxation meets adventure! Immerse yourself in a world of exciting activities tailored for all ages and interests. From serene spa treatments to thrilling water sports, our resort offers a perfect blend of leisure and excitement.


            </div>
          </div>
          <div className="h-container">
            {/*Block*/}
            <div className="history-block">
              <div className="row clearfix">
                <div className="content-col col-lg-6 col-md-6 col-sm-12">
                  <div
                    className="inner wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="image-box">
                      <div className="image">
                        <img src={swim} alt="swimming pool" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="empty-col col-lg-6 col-md-6 col-sm-12">
                  <div className="lower-box">
                    <h3>Swimming</h3>
                    <div className="text">
                    Immerse yourself in the crystal-clear waters while surrounded by the harmonious blend of nature and architectural elegance. 
                    <br/>Our attention to detail is reflected in every aspect of this aquatic haven, from the chic lounge chairs to the ambient lighting that transforms the poolside into a mesmerizing retreat after sunset. This is not just a pool; it's an invitation to unwind in style, where every moment is a celebration of refined relaxation.
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*Block*/}
            <div className="history-block alternate">
              <div className="row clearfix">
                <div className="content-col col-lg-6 col-md-6 col-sm-12">
                  <div className="inner">
                    <div className="image-box">
                      <div className="image">
                        <img src={rain} alt="rain dance" />
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div className="empty-col col-lg-6 col-md-6 col-sm-12 mt-lg-5">
                <div className="lower-box">
                      <h3>Rain Dance</h3>
                      <div className="text">
                      The synergy of lively music and the refreshing embrace of falling water creates an atmosphere of pure joy and liberation. Our rain dance area is not just a space; it's a sensory experience that invites you to let loose, dance freely, and celebrate the sheer pleasure of being alive. The carefully curated playlist and the artfully crafted water jets ensure that every moment spent in this dynamic environment is a sensory feast for the soul.
                      </div>
                    </div>
                </div>
              </div>
            </div>
            {/*Block*/}
            <div className="history-block">
              <div className="row clearfix">
                <div className="content-col col-lg-6 col-md-6 col-sm-12">
                  <div
                    className="inner"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="image-box">
                      <div className="image">
                        <img src={games} alt="indoor-outdoor games" />
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div className="empty-col col-lg-6 col-md-6 col-sm-12 mt-lg-5">
                <div className="lower-box">
                      <h3>Indoor-Outdoor Games</h3>
                      <div className="text">
                      Join us in the joyful pursuit of creating lasting memories through the spirit of play. In our vibrant enclave, every game is more than a competition; it's a celebration of fun and connection. Whether you're challenging friends to a friendly match, engaging in lively team sports, or simply enjoying the thrill of board games, our atmosphere is infused with the essence of camaraderie and laughter. 
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default About;
